<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="dismiss">
            <ion-icon slot="icon-only" :icon="icons.close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          {{ $t('modals.filters') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ODNModalFilters>
        <ion-list>
          <!-- Projects -->
          <ion-list-header>
            <ion-label>{{ $t('labels.filterProjects') }}</ion-label>
            <ion-button @click="onProjectsModal">
              <ion-icon slot="icon-only" :icon="icons.search"></ion-icon>
            </ion-button>
            <ion-button
              v-if="newFilters.projects.length"
              @click="onRemoveAllProjects"
              color="danger"
            >
              <ion-icon slot="icon-only" :icon="icons.trashOutline"></ion-icon>
            </ion-button>
          </ion-list-header>
          <ion-item lines="none">
            <ion-chip
              v-for="project in newFilters.projects"
              :key="project.id"
              @click="onRemoveProject(project)"
            >
              <ion-label>{{ project.name }}</ion-label>
              <ion-icon :icon="icons.closeCircle"></ion-icon>
            </ion-chip>
            <ion-text v-if="!newFilters.projects.length" color="medium">
              {{ $t('filters.allProjects') }}
            </ion-text>
          </ion-item>
          <!-- Appointment types -->
          <ion-list-header>
            <ion-label>{{ $t('labels.filterAppointments') }}</ion-label>
          </ion-list-header>
          <ion-item lines="none">
            <ion-label>{{ $t('fields.appointmentTypes.label') }}</ion-label>
            <ion-select
              v-if="!appointmentTypesLoading"
              v-model="newFilters.appointmentTypes"
              :multiple="true"
              :placeholder="$t('fields.appointmentTypes.placeholder')"
              :cancelText="$t('buttons.cancel')"
              :okText="$t('buttons.validate')"
            >
              <ion-select-option
                v-for="appointmentType in appointmentTypes"
                :key="appointmentType.id"
                :value="appointmentType.id"
              >
                {{ $t(`appointmentTypes.${appointmentType.name}`) }}
              </ion-select-option>
            </ion-select>
            <ion-spinner v-else></ion-spinner>
          </ion-item>
          <!-- Appointment statuses -->
          <ion-item lines="none">
            <ion-label>{{ $t('fields.appointmentStatuses.label') }}</ion-label>
            <ion-select
              v-if="!appointmentStatusesLoading"
              v-model="newFilters.appointmentStatuses"
              :multiple="true"
              :placeholder="$t('fields.appointmentStatuses.placeholder')"
              :cancelText="$t('buttons.cancel')"
              :okText="$t('buttons.validate')"
            >
              <ion-select-option
                v-for="appointmentStatus in appointmentStatuses"
                :key="appointmentStatus.id"
                :value="appointmentStatus.id"
              >
                {{ $t(`appointmentStatuses.${appointmentStatus.name}`) }}
              </ion-select-option>
            </ion-select>
            <ion-spinner v-else></ion-spinner>
          </ion-item>
          <!-- Order by -->
          <ion-list-header>
            <ion-label>{{ $t('labels.sortBy') }}</ion-label>
          </ion-list-header>
          <ion-item lines="none">
            <ion-segment
              scrollable
              mode="ios"
              v-model="newFilters.sort"
              style="width: 100%"
            >
              <ion-segment-button value="appointment.id">
                <ion-label>{{ $t('filters.id') }}</ion-label>
              </ion-segment-button>
              <ion-segment-button value="appointment.title">
                <ion-label>{{ $t('filters.title') }}</ion-label>
              </ion-segment-button>
              <ion-segment-button value="project.name">
                <ion-label>{{ $t('filters.project') }}</ion-label>
              </ion-segment-button>
              <ion-segment-button value="appointment.intervention">
                <ion-label>{{ $t('filters.intervention') }}</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-item>
          <!-- Order direction -->
          <ion-list-header>
            <ion-label>{{ $t('labels.sortDirection') }}</ion-label>
          </ion-list-header>
          <ion-item lines="none">
            <ion-segment mode="ios" v-model="newFilters.direction">
              <ion-segment-button value="asc" layout="icon-start">
                <ion-icon :icon="icons.arrowUpOutline"></ion-icon>
                <ion-label>
                  {{ $t('labels.ascending') }}
                </ion-label>
              </ion-segment-button>
              <ion-segment-button value="desc" layout="icon-start">
                <ion-icon :icon="icons.arrowDownOutline"></ion-icon>
                <ion-label>
                  {{ $t('labels.descending') }}
                </ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-item>
        </ion-list>
        <div class="odn-modal-filters-buttons">
          <ion-button size="block" color="light" @click="reset">
            <ion-icon :icon="icons.refreshOutline" slot="start"></ion-icon>
            <ion-label>
              {{ $t('buttons.reset') }}
            </ion-label>
          </ion-button>
          <ion-button size="block" @click="dismissWithData">
            <ion-icon
              :icon="icons.checkmarkCircleOutline"
              slot="start"
            ></ion-icon>
            <ion-label>
              {{ $t('buttons.validate') }}
            </ion-label>
          </ion-button>
        </div>
      </ODNModalFilters>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonListHeader,
  IonList,
  IonItem,
  IonChip,
  IonText,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  modalController,
  toastController,
} from '@ionic/vue';
import {
  close,
  closeCircle,
  search,
  trashOutline,
  arrowUpOutline,
  arrowDownOutline,
  refreshOutline,
  checkmarkCircleOutline,
} from 'ionicons/icons';

import ODNModalFilters from '@m/odn-modal-filters.vue';
import ODNModalProjects from '@m/odn-modal-project-list.vue';

import APIService from '@s/api.service';

export default {
  name: 'ModalStoreAppointmentsFilters',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonListHeader,
    IonList,
    IonItem,
    IonChip,
    IonText,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    ODNModalFilters,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        close,
        search,
        trashOutline,
        closeCircle,
        arrowUpOutline,
        arrowDownOutline,
        refreshOutline,
        checkmarkCircleOutline,
      },
      newFilters: {
        projects: [].concat(this.filters.projects),
        appointmentTypes: [].concat(this.filters.appointmentTypes),
        appointmentStatuses: [].concat(this.filters.appointmentStatuses),
        sort: this.filters.sort,
        direction: this.filters.direction,
      },
      appointmentTypes: [],
      appointmentTypesLoading: true,
      appointmentStatuses: [],
      appointmentStatusesLoading: true,
    };
  },
  created() {
    this.fetchAppointmentTypes();
    this.fetchAppointmentStatuses();
  },
  methods: {
    reset() {
      this.newFilters = {
        projects: [],
        appointmentTypes: [],
        appointmentStatuses: [],
        sort: 'appointment.startsAt',
        direction: 'desc',
      };
      this.dismissWithData();
    },
    dismiss() {
      modalController.dismiss();
    },
    dismissWithData() {
      modalController.dismiss(this.newFilters);
    },
    async fetchAppointmentTypes() {
      this.appointmentTypesLoading = true;
      try {
        this.appointmentTypes = (
          await APIService.get(`/appointment-types`)
        ).data;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.appointmentTypes.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.appointmentTypesLoading = false;
      }
    },
    async fetchAppointmentStatuses() {
      this.appointmentStatusesLoading = true;
      try {
        this.appointmentStatuses = (
          await APIService.get(`/appointment-statuses`)
        ).data;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.appointmentStatuses.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.appointmentStatusesLoading = false;
      }
    },
    // Projects
    async onProjectsModal() {
      const modal = await modalController.create({
        component: ODNModalProjects,
        componentProps: {
          selected: this.newFilters.projects,
        },
      });
      await modal.present();

      const newProject = (await modal.onDidDismiss()).data;

      if (newProject) {
        this.newFilters.projects.push(newProject);
      }
    },
    onRemoveAllProjects() {
      this.newFilters.projects = [];
    },
    onRemoveProject(project) {
      this.newFilters.projects = this.newFilters.projects.filter(
        (r) => r.id !== project.id
      );
    },
  },
};
</script>
